/*
 * @Author: cxd
 * @Date: 2022-05-22 02:33:36
 * @LastEditors: cxd
 * @LastEditTime: 2022-05-22 15:03:37
 * @Description: 通行
 */
import { fetchApi } from "@/utils/axios"
import http from "@/utils/axios"
import URL from "@/api/urlConfig"

// 获取工作人员白名单列表
export const getStaffWhiteList = (page, size, data) => {
    const submitData = {
        current: page,
        size: size,
        ...data,
    }
    return fetchApi(URL.STAFF_WHITE_LIST, submitData, 'post', 'data')
}

// 禁用工作人员权限
export const disableUserAuthority = (id, state) => {
    return fetchApi(URL.DISABLE_USER_AUTHORITY + '?id=' + id + '&state=' + state, null, 'PUT')
}

// 删除工作人员权限
export const deleteUserAuthority = (id) => {
    return fetchApi(URL.DELETE_USER_AUTHORITY + '?id=' + id, null, 'DELETE')
}

// 获取企业员工白名单列表
export const getUserWhiteList = (page, size, data) => {
    const submitData = {
        current: page,
        size: size,
        ...data,
    }
    return fetchApi(URL.GET_USER_WHITE_LIST, submitData, 'post', 'data')
}

// 禁用企业员工权限
export const disableEnterpriseUserAuthority = (id, state) => {
    return fetchApi(URL.DISABLE_ENTERPRISE_USER_AUTHORITY + '?id=' + id + '&state=' + state, null, 'PUT')
}

// 删除企业员工权限
export const deleteEnterpriseUserAuthority = (id) => {
    return fetchApi(URL.DELETE_ENTERPRISE_USER_AUTHORITY + '?id=' + id, null, 'DELETE')
}

// 上传企业员工白名单
export const uploadEmployeesWhiteList = (data) => {

}









// 查看详情
export const orderDetal = (id) => {
    return fetchApi(URL.ORDER_DETAL + '/' + id)
}

// 导出
export const orderExport = (data) => {
    return http({
        url: URL.ORDER_EXPORT,
        responseType: 'blob',
        method: 'POST',
        data
    })
}

